export const MenuItems = [
    {
        title: "Home",
        url : "/",
        cName : "nav-links",
        icon : "fa-solid fa-house-user",
    },
    {
        title: "About",
        url : "/about",
        cName : "nav-links",
        icon : "fa-solid fa-address-card",
    },
    {
        title: "Service",
        url : "/Service",
        cName : "nav-links",
        icon : "fa-solid fa-briefcase",
    },
    {
        title: "Contact",
        url : "/contact",
        cName : "nav-links",
        icon : "fa-solid fa-address-book",
    },
    {
        title: "Sign Up",
        url : "/signup",
        cName : "nav-links-mobile",
        icon : "fa-solid fa-right-to-bracket",
    },
]